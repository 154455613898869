import { post, deletes, get, downFile } from 'common/request'
export default {
  //   // 网格树
  //  gridTree:args => get('/scGridManagement/getTreeList', args),
  // //  分页
  // list:args => get('/scGridWorker/queryList', args),
  // // 部门人员树
  // deptTree:args => get('/dept/deptUserTree', args),
  // // 新增
  // add:args => post('/scGridWorker/add', args),
  // // 编辑
  // edit:args => post('/scGridWorker/update', args),
  // // 删除
  // dels:args => post(`/scGridWorker/delete?id=${args}`),
  // // 详情
  // dels:args => post(`/scGridWorker/delete?id=${args}`),
  // // 详情
  // gridDetail:args => get(`/scGridWorker/getById?id=${args}`),
  // //导出
  // export: args => downFile('/scGridWorker/export', args),
  // //批量删除
  //  deleteBatch:args => post(`/scGridWorker/deleteBatch`,args),
  //  资讯分类分页
  classifyList: args => get('/hotInfo/informationType/list', args),
  //  资讯分类分页启用状态修改
  classifyFlag: args => post('/hotInfo/informationType/update', args),
  // 新增分类
  addClassify: args => post('/hotInfo/informationType/add', args),
  // 批量删除分类
  deleteBatch: args => deletes(`/hotInfo/informationType/ids`, args),
  // 删除分类
  dels: args => deletes(`/hotInfo/informationType/deleteById`, args),
  //  资讯发布分页
  issueList: args => get('/hotInfo/informationIssue/list', args),
  // 咨询分类查询所有
  classifyListall: args => get('/hotInfo/informationType/listAll', args),
  // 新增资讯发布
  addIssue: args => post('/hotInfo/informationIssue/add', args),
  // 批量删除发布
  deletesIssue: args => deletes(`/hotInfo/informationIssue/ids`, args),
  // 删除发布
  delIssue: args => deletes(`/hotInfo/informationIssue/deleteById`, args),

}
