
<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      width="50%"
      :z-index="99"
      :close-on-click-modal="true"
      :show-close="true"
    >
      <div class="notice-title">
        {{ info.informationTitle }}
      </div>
      <div class="notice-time">消息分类: {{info.informationTypeName || '--'}} &nbsp;&nbsp; 发布时间: {{ info.createTime }}</div>
      <div class="notice-msg" v-html="info.content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="background-color: #556bff"
          @click="mapCancel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
   
   <script>
import api from "../api";
export default {
  components: {},
  data() {
    return {
      api,
      visible: false,
      fileType: true,
      title: "",
      info: {},
    };
  },

  methods: {
    openDialog(e) {
      this.visible = true;
      console.log(e);
      this.title = e.title;
      this.info = e;
    },

    mapCancel() {
      this.visible = false;
    },
  },

  created() {},

  mounted() {},
};
</script>
   <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.notice-title {
  display: flex;
  justify-content: center;
  font-size: 0.09rem;
  font-weight: 700;
  color: #000;
}
.notice-time {
  margin-top: 20px;
  height: 0.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef2f5;
}
.notice-msg {
  margin-top: 20px;
  min-height: 1.5rem;
  max-height: 2.2rem;
  overflow-y: auto;
}
</style>