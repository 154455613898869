
<template>
  <!-- 资讯分类 -->
  <div class="">
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :toolActions="toolActions"
      :dataSourceApi="api.classifyList"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      @bulkDeletion="deleteSecurityPersonnel"
      @add="addDialog"
      @pop-confirm="popConfirm"
      @action-column-click="actionColumnClick"
    >
      <a-switch
        v-model="record.flag"
        slot="flag"
        slot-scope="{ record }"
        @change="flagChange(record)"
      />
    </ax-table>

    <el-dialog
      :title="titles"
      :visible.sync="visible"
      width="30%"
      :z-index="99"
      :close-on-click-modal="true"
      :show-close="true"
    >
      <ax-form ref="form" :formBuilder="formBuilder">
        <template slot="address">
          <a-switch v-model="flag" />
        </template>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="background-color: #556bff"
          @click="submit"
          :loading="saveType"
          >保 存</el-button
        >
        <el-button @click="Cancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
 <script>
const searchForm = [
  {
    label: "分类名称",
    type: "",
    model: "typeName",
    options: {
      placeholder: "请输入",
    },
    formItem: {},
    col: { span: 6 },
  },
];
const formList = [
  {
    label: "名称",
    type: "input",
    model: "typeName",
    options: { maxLength: 20, placeholder: "请输入..." },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入..." }],
  },
  {
    label: "排序",
    type: "inputNumber",
    model: "sort",
    options: { placeholder: "请输入数字,数字越小排序越前..." },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入..." }],
  },
  {
    label: "是否启用",
    type: "textarea",
    model: "address",
    formItem: {},
    col: { span: 24 },
  },
];
import api from "../api";
import { initGridFormData } from "@/common/tools";
export default {
  components: {},
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "名称",
            dataIndex: "typeName",
            ellipsis: true,
            key: 2,
            width: 100,
          },
          {
            title: "操作人",
            dataIndex: "updateBy",
            ellipsis: false,
            key: 3,
            width: 100,
          },
          {
            title: "更新日期",
            dataIndex: "updateTime",
            ellipsis: true,
            key: 4,
            width: 130,
          },
          {
            title: "排序",
            dataIndex: "sort",
            ellipsis: true,
            width: 80,
            key: 5,
          },
          {
            title: "是否启用",
            dataIndex: "flag",
            ellipsis: false,
            key: 6,
          },
        ],
        false,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#556bff",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                };
              },
            },
          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),
      // 操作按钮
      toolActions: [
        { name: "add", text: "新增" },
        { name: "bulkDeletion", text: "批量删除", type: "#f95a5a" },
      ],
      visible: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
      saveType: false,
      flag: false,
      titles: "",
      id:'',
      // 多选
      selectedRowKeys: [],
    };
  },
  computed: {},
  methods: {
    addDialog() {
      this.visible = true;
      this.titles = "新增资讯分类";
    },
    Cancel() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    // 启用状态
    async flagChange(record) {
      try {
        await api.classifyFlag({
          id: record.id,
          flag: record.flag,
        });
        this.$refs.tableBox.getDataSource();
      } catch (error) {
        console.log(error);
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        let that = this;
        // 批量删除添加二次确认
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async function () {
            const res = await api.deleteBatch({
              ids: that.selectedRowKeys.join(","),
            });
            if (res.status === 200) {
              that.$message.success("删除成功");
              // 刷新列表数据
              that.$refs.tableBox.getDataSource();
            } else {
              that.$message.error("删除失败");
            }
          },
        });
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      try {
        const res = await api.dels({ id: record.id });
        if (res.status === 200) {
          this.$message.success("删除成功");
          this.$refs.tableBox.getDataSource();
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
          this.visible = true;
          this.titles = "编辑资讯分类";
          this.$nextTick(() => {
            this.$refs.form.setFieldsValue(record);
            this.flag = record.flag;
            this.id = record.id;
          });
          break;
      }
    },
    // 弹窗提交
    submit() {
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        try {
          let obj = {
            ...value,
            flag: this.flag,
          };
          if (this.titles === "新增资讯分类") {
            const res = await api.addClassify(obj);
            if (res.status == 200) {
              this.$message.success("操作成功");
              this.Cancel();
              this.saveType = false;
              this.$refs.tableBox.getDataSource();
            } else {
              this.$message.error("操作失败");
              this.saveType = false;
            }
          }else{
            const res = await api.classifyFlag({...obj,id:this.id});
            if (res.status == 200) {
              this.$message.success("操作成功");
              this.Cancel();
              this.saveType = false;
              this.$refs.tableBox.getDataSource();
            } else {
              this.$message.error("操作失败");
              this.saveType = false;
            }
          }
        } catch (error) {
          console.error(error);
          this.saveType = false;
        }
      });
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
/deep/.ant-switch-checked {
  background-color: #2a5cff;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-switch {
  background-color: #bfbfbf;
}
.ant-switch-checked {
  background-color: #409eff;
}
</style>