
<template>
  <!-- 资讯发布 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :toolActions="toolActions"
      :dataSourceApi="api.issueList"
      @add="openDialog"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      @bulkDeletion="deleteSecurityPersonnel"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
    </ax-table>
    <!-- 新增弹窗 -->
    <lssueDialog ref="lssueDialog" @refresh="refresh"></lssueDialog>
    <!-- 详情弹窗 -->
    <issueDetails ref="issueDetails"></issueDetails>
  </div>
</template>
 
 <script>
import lssueDialog from "./lssueDialog.vue";
import issueDetails from "./issueDetails.vue";
import api from "../api";
import { initGridFormData } from "@/common/tools";
const searchForm = [
  {
    label: "标题",
    type: "",
    model: "informationTitle",
    options: {
      placeholder: "请输入",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属分类",
    type: "select",
    model: "informationType",
    options: {
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },
  // {
  //   label: "文章类型",
  //   type: "datePicker",
  //   model: "time",
  //   options: {
  //     placeholder: "请选择",
  //   },
  //   formItem: {},
  //   col: { span: 6 },
  // },
];

export default {
  components: { lssueDialog, issueDetails },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "标题",
            dataIndex: "informationTitle",
            ellipsis: true,
            key: 2,
            width: 150,
          },
          {
            title: "分类",
            dataIndex: "informationTypeName",
            ellipsis: true,
            key: 3,
            width: 150,
          },
          // {
          //   title: "文章类型",
          //   dataIndex: "gridName",
          //   ellipsis: true,
          //   key: 4,
          //   width: 130,
          // },
          {
            title: "浏览次数",
            dataIndex: "count",
            ellipsis: true,
            width: 80,
            key: 5,
          },
          {
            title: "发布人",
            dataIndex: "createBy",
            ellipsis: true,
            key: 6,
          },
          {
            title: "发布时间",
            dataIndex: "createTime",
            ellipsis: true,
            key: 7,
          },
        ],
        false,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "detail",
                  type: "#556bff",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#f95a5a",
                  link: true,
                  popDisabled: false, //delete是false是禁用
                  // disabled: !(record.status === "1"),
                };
              },
            },
          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),

      // 操作按钮
      toolActions: [
        { name: "add", text: "新增" },
        { name: "bulkDeletion", text: "批量删除", type: "#f95a5a" },
      ],
      visible: false,
      // 多选
      selectedRowKeys: [],
    };
  },

  methods: {
    openDialog() {
      this.$refs.lssueDialog.openDialog();
    },
    // 列表查询
    async getListall() {
      try {
        const res = await api.classifyListall();
        this.$nextTick(() => {
          const options = res.data.map((res) => {
            return { label: res.typeName, value: res.id };
          });
          this.$refs.logTable.$children[0].$refs.searchForm.setFormItemProp(
            "informationType",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "请选择所属分类",
              },
            }
          );
        });
      } catch (error) {
        console.log(error);
      }
    },
    // 操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "detail":
          this.$refs.issueDetails.openDialog(record);
          break;
      }
    },
    // 表格刷新
    refresh() {
      this.$refs.logTable.getDataSource();
    },
    // 根据id删除
    async popConfirm({ record }) {
      try {
        const res = await api.delIssue({ id: record.id });
        if (res.status === 200) {
          this.$message.success("删除成功");
          this.$refs.logTable.getDataSource();
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        let that = this;
        // 批量删除添加二次确认
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async function () {
            const res = await api.deletesIssue({
              ids: that.selectedRowKeys.join(","),
            });
            if (res.status === 200) {
              that.$message.success("删除成功");
              // 刷新列表数据
              that.$refs.logTable.getDataSource();
            } else {
              that.$message.error("删除失败");
            }
          },
        });
      }
    },
  },

  created() {},

  mounted() {
    this.getListall();
  },
};
</script>
 <style lang='less' scoped>
</style>