import { render, staticRenderFns } from "./infoIssue.vue?vue&type=template&id=8eaa2c3a&scoped=true"
import script from "./infoIssue.vue?vue&type=script&lang=js"
export * from "./infoIssue.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8eaa2c3a",
  null
  
)

export default component.exports