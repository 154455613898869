
<template>
  <div>
    <el-dialog
      title="新增热点资讯"
      :visible.sync="visible"
      width="50%"
      :z-index="99"
      :close-on-click-modal="true"
      :show-close="true"
    >
      <ax-form ref="formBox" :formBuilder="formBuilder">
        <div
          slot="content"
          v-decorator="[
            'content',
            {
              rules: [{ required: true, message: '正文不能为空' }],
            },
          ]"
        >
          <quillEditor @onChange="quillHtml" ref="quillEditor" />
        </div>
        <imageUpload slot="fileIds" ref="imageUpload"></imageUpload>
      </ax-form>
      <!-- <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <a-input
            placeholder="请输入标题..."
            v-model="titles"
            :maxLength="50"
          />
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <a-select
            style="width: 100%"
            placeholder="请选择资讯分类..."
            allowClear
            v-model="selectValue"
          >
            <a-select-option
              :value="item.value"
              v-for="item in options"
              :key="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <quillEditor
            @onChange="quillHtml"
            v-if="fileType"
            ref="quillEditor"
          ></quillEditor>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]" style="padding-left: 10px">
         图片上传 -->
      <!-- <imageUpload ref="imageUpload"></imageUpload>
      </a-row> -->
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="background-color: #556bff"
          :loading="saveType"
          @click="confirm"
          >保 存</el-button
        >
        <el-button @click="mapCancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
 <script>
import quillEditor from "../../../../Acomponents/quillEditor/wangeditor.vue";
import imageUpload from "../../../../Acomponents/ax-image-modal/src/image-upload.vue";
import api from "../api";
const formList = [
  {
    label: "标题",
    type: "",
    model: "informationTitle",
    options: { placeholder: "请输入标题", maxLength: 50 },
    col: { span: 12 },
    rules: [{ required: true, message: "请输入标题" }],
  },
  {
    label: "分类",
    type: "select",
    model: "informationType",
    options: { placeholder: "请选择分类" },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择分类" }],
  },
  {
    label: "正文",
    type: "",
    model: "content",
    options: {},
    col: { span: 24 },
    rules: [{ required: true, message: "正文不能为空" }],
  },
  {
    label: "封面图片",
    type: "",
    model: "fileIds",
    options: {},
    col: { span: 24 },
    rules: [{ required: true, message: "正文不能为空" }],
  },
];
// import api from "../announcementList/api";
export default {
  components: { quillEditor, imageUpload },
  data() {
    return {
      api,
      visible: false,
      content: "",
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },

  methods: {
    openDialog() {
      this.visible = true;
      this.getListall();
    },
    mapCancel() {
      this.visible = false;
      this.content = "";
      this.$refs.imageUpload.removeFileList();
      this.$refs.quillEditor.refresh();
      this.$refs.formBox.resetFields();
    },
    async confirm() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        try {
          let fileIds = [];
          this.$refs.imageUpload.getFile()[0]
            ? (fileIds = [this.$refs.imageUpload.getFile()[0].response.data.id])
            : (fileIds = []);
          value.fileIds = fileIds
          const res = await api.addIssue(value);
          if (res.status == 200) {
            this.$message.success("操作成功");
            this.mapCancel();
            this.saveType = false;
            this.$emit("refresh");
          } else {
            this.$message.error("操作失败");
            this.saveType = false;
          }
          this.saveType = false;
        } catch (error) {
          console.error(error);
          this.saveType = false;
        }
      });
    },

    // 富文本编辑器获取内容
    quillHtml(e) {
      this.content = e;
      this.$refs.formBox.setFieldsValue({
        content: this.content,
      });
    },
    // 列表查询
    async getListall() {
      try {
        const res = await api.classifyListall();
        this.$nextTick(() => {
          let options = res.data.map((res) => {
            return { label: res.typeName, value: res.id };
          });
          this.$refs.formBox.setOptions(
            ["informationType"],
            "options",
            options
          );
        });
      } catch (error) {
        console.log(error);
      }
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>