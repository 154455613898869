
<template>
  <div>
    <a-tabs default-active-key="1">
      <!-- @change="callback" -->
      <!-- 资讯发布 -->
      <a-tab-pane key="1" tab="资讯发布">
        <infoIssue></infoIssue>
      </a-tab-pane>
      <!-- 资讯分类 -->
      <a-tab-pane key="2" tab="资讯分类" force-render>
        <infoIClassify></infoIClassify>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
 
 <script>
import infoIssue from "./components/infoIssue.vue";
import infoIClassify from "./components/infoIClassify.vue";
export default {
  components: { infoIssue, infoIClassify },
  data() {
    return {
    };
  },

  methods: {},

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
</style>